import { Link } from 'react-router-dom'
import styled from 'styled-components'
import bg_gif from '../../assets/images/home.gif'
import logo_png from '../../assets/images/logo_branco.png'


const Page = styled.div`
    height: 100%;
    width: 100%;

    background-image: url(${bg_gif});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    display: flex;
    align-items: center;
    justify-content: center;

    color: white;
    font-weight: 700;
`

const Logo = styled.div`
    position: absolute;
    top: 10px;
    left: 10px;

    background-image: url(${logo_png});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    height: 80px;
    width: 80px;

    z-index: 2;
`

const Area = styled.div`
    height: 400px;
    width: 310px;

    // min-height: 350px;
    // min-width: 300px;

    // width: 90%;
    // height: 50%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    z-index: 2;
`

const Text = styled.div`
    transition: 0.3s ease all;

    ${props => props.off && "filter: blur(50px); opacity: 0;"}

    display: flex;
    align-items: center;
    justify-content: center;

    text-align: center;

    height: 45%;
    max-width: 90%;
    font-size: 1.8em;
    line-height: 1.3em;
`

const ButtonArea = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;

    height: 55%;
    width: 85%;
`

const ToggleButtonArea = styled.div`
    height: 25%;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: space-between;
`

const BigButtonArea = styled.div`
    height: 60%;
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
`

const ToggleButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    width: 45%;
    height: 60%;
    border: 3px solid white;
    border-radius: 100px;
    ${props => props.active && "background-color: white; color: black;"}

    transition: 0.5s ease all;
    &:active {
        transform: scale(1.02) rotate(1.5deg);
    }

    cursor: pointer;
`

const Button = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 40%;
    border: 3px solid white;
    border-radius: 10px;
    ${props => props.active && "background-color: white; color: black;"}

    transition: 0.1s ease all;
    &:active {
        transform: scale(1.1) rotate(3deg);
    }

    cursor: pointer;
`

const ButtonLink = styled(Link)`
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 40%;
    border: 3px solid white;
    border-radius: 10px;
    ${props => props.active && "background-color: white; color: black;"}

    transition: 0.1s ease all;
    &:active {
        transform: scale(1.1) rotate(3deg);
    }

    cursor: pointer;

    text-decoration: none; color: white;
`

const Clip = styled.div`
    background: no-repeat center center;
    background-size: 100%;
    color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    ${props => props.active && `background-image: url(${bg_gif});`}
`

const Overlay = styled.div`
    height: 100vh;
    width: 100vw;

    position: fixed;
    top: 0;
    left: 0;

    background-color: rgba(0, 0, 0, 0.3);
    z-index:1;
`

export {
    Page,
    Logo,
    Area,
    Text,
    ButtonArea,
    ToggleButtonArea,
    BigButtonArea,
    ToggleButton,
    Button, ButtonLink,
    Clip,
    Overlay
}