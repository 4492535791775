import { FormContainer, Input, Submit } from "./styles"

import { useState } from "react"
import client from "../../assets/service/client"

const ForgotPasswordForm = () => {
    const [email, setEmail] = useState('')
    const [message, setMessage] = useState('')

    const onSubmit = () => {
        client.post("/auth/forgot/link", {
            email,
        })
        .then(res => {
            setMessage("Foi enviado um email para redefinir a sua senha.")
        })
        .catch(err => {
            setMessage("Esse email não está cadastrado.")
        })
    }

    return (
        <FormContainer>
            <Input type="email" name="email" placeholder="email" style={{textTransform: "lowercase"}} value={email} onChange={(event) => setEmail(event.target.value)}/>
            <Submit onClick={() => onSubmit()}>Mandar email</Submit>
            <div style={{width: "100%", textAlign: "center"}}>
            { message && <span>{message}</span>}
            </div> 
        </FormContainer>
    )
}

export default ForgotPasswordForm;