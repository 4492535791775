import styled from "styled-components";

import logo from '../../assets/images/ganda.svg'
// import logo from '../../assets/images/logo_reduzido.png'
// import logo from '../../assets/images/G.svg'

const HeaderWrapper = styled.div`
    position: fixed;
    top: 0;
    width: 100%;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: #fff;

    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    z-index: 5;
`

const Header = styled.div`
    // height: 60px;
    width: 90%;

    display: flex;
    justify-content: space-evenly;
    align-items: center;
`

const Logo = styled.div`
    margin-top: 10px;
    width: calc(157px * 0.7);
    height: calc(26px * 0.7);

    background-image: url(${logo});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
` // GANDAYA

// const Logo = styled.div` 
//     width: 70px;
//     height: 70px;

//     transform: rotate(-5deg);

//     background-image: url(${logo});
//     background-size: cover;
//     background-repeat: no-repeat;
//     background-position: center;
// ` // Reduzido

// const Logo = styled.div`
//     margin-top: 10px;
//     width: 30px;
//     height: 30px;

//     background-image: url(${logo});
//     background-size: cover;
//     background-repeat: no-repeat;
//     background-position: center;
// ` // G

const HeadItem = styled.div`
    height: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;

    font-size: 1.5em;
    color: gray;

    transition: 0.1s ease all;

    &:active {
        transform: scale(1.1) rotate(3deg);
    }
`

const Menu = styled.div`
    height: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;

    font-size: 1.7em;
    color: #454545;

    transition: 0.1s ease all;

    position: absolute;
    left: 30px;

    &:active {
        transform: scale(1.1) rotate(3deg);
    }
`

const Support = styled.a`
    height: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;

    font-size: 1.7em;
    color: #454545;

    transition: 0.1s ease all;

    position: absolute;
    right: 30px;

    &:active {
        transform: scale(1.1) rotate(3deg);
    }
`

export {
    HeaderWrapper,
    Header,
    Logo,
    HeadItem,
    Menu, Support
};