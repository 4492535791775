import { useState } from "react"
import { Link, useParams } from "react-router-dom";
import client from "../../assets/service/client"
import useStore from "../../store/store";
import { FormContainer, Input, Submit } from "./styles"

const Forgot = (props) => {
    const { id } = useParams();
    const setUser = useStore(state => state.setUser);
	const dropUser = useStore(state => state.dropUser);

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    const [message, setMessage] = useState('')

    const onSubmit = () => {
        client.post("/auth/forgot", {
            link: id,
            email,
            password
        })
        .then(res => {
            setMessage("Senha nova gravada com sucesso. Você será redirecionado para a página principal.")
            setTimeout(() => {
                document.getElementById("refresh").click()
            }, 3000)
        })
        .catch(err => {
            setMessage(err.response.data.message)
        })
    };
    
    return (
        <FormContainer>
            <Input type="email" name="email" placeholder="email" style={{textTransform: "lowercase"}} value={email} onChange={(event) => setEmail(event.target.value)}/>
            <Input type="password" name="password" placeholder="senha" value={password} onChange={(event) => setPassword(event.target.value)}/>
            <Submit onClick={() => onSubmit()}>Gravar</Submit>
            { message && <div style={{textAlign: "center"}}><span>{message}</span></div> }
            <Link id="refresh" to="/events" style={{display: 'none'}} />
        </FormContainer>
    )
}

export default Forgot